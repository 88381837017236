import React, { useContext, useState, useEffect } from "react";
import { db, firestore } from "../firebase";
import { useAuth } from "./AuthContext";
import { geohashForLocation } from "geofire-common";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  const { currentUser } = useAuth();

  function GetAllCatches() {
    const [fish, setFish] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("catches")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFish(data);
        });
      return unsubscribe;
    }, []);
    return fish;
  }

  function GetIncomingCatches() {
    const [fish, setFish] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("catches")
        .where("entered", "==", true)
        .where("processed", "==", false)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFish(data);
        });
      return unsubscribe;
    }, []);
    return fish;
  }

  function GetApprovedCatches() {
    const [fish, setFish] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("catches")
        .where("approved", "==", true)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFish(data);
        });
      return unsubscribe;
    }, []);
    return fish;
  }

  function GetSiteEmails() {
    const [emails, setEmails] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("site_emails")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEmails(data);
        });
      return unsubscribe;
    }, []);
    return emails;
  }

  function GetAppUsers() {
    const [users, setUsers] = useState([]);
    useEffect(() => {
      const unsubscribe = db.collection("users").onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          value: doc.id,
          label: `${doc.data().display_name} - ${doc.data().email}`,
        }));
        setUsers(data);
      });
      return unsubscribe;
    }, []);
    return users;
  }

  async function getCatchVids(catchId) {
    const catchRef = db.collection("catches").doc(catchId);

    console.log(`/${catchRef.path}`);

    return db
      .collection("video_uploads")
      .where("catchRef", "==", catchRef)
      .get()
      .then((snap) => {
        if (!snap.empty) {
          console.log("Got the catch");
          return snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
        }
      });
  }

  async function approveCatch(catchId) {
    const fishRef = db.collection("catches").doc(catchId);
    const fishSnap = await fishRef.get();
    const fish = fishSnap.data();

    const anglerId = fish.uid;
    // Get the entry Id
    const competition_id = fish.competition_ids[0];
    const entrySnap = await db
      .collection("entries")
      .where("competition", "==", competition_id)
      .where("uid", "==", anglerId)
      .get();
    const entry_id = entrySnap.docs[0].id;

    const catchEntry = {
      catchData: fish,
      entryTime: fish.timestamp.toDate(),
      entryId: entry_id,
      competitionId: competition_id,
      compCollection: "competitions",
      catchRef: fishSnap.ref,
      status: "approved",
    };
    await db.collection("entry_catches").add(catchEntry);

    return fishRef.update({
      approved: true,
      processed: true,
      logged: false,
    });
  }

  async function declineCatch(catchId, declineReason) {
    return new Promise(async (res, rej) => {
      const catchRef = db.collection("catches").doc(catchId);
      await db.collection("decline_messages").add({
        declineReason,
        catchId,
      });
      const videos = await db
        .collection("video_uploads")
        .where("catchRef", "==", catchRef)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            return snap.docs.map((doc) => {
              return doc.ref.delete();
            });
          }
        });
      await Promise.all(videos);
      await catchRef
        .update({
          entered: false,
          processed: false,
        })
        .then(() => {
          res();
        });
    });
  }

  function togglePro(userId, state) {
    return db.collection("users").doc(userId).update({
      pro: state,
    });
  }

  function submitCatch(data) {
    const convertedWeight = parseFloat(data.weight);
    return db.collection("catches").add({
      display_name: currentUser.display_name || "N/A",
      entered: data.compEntry,
      photo_url: data.photo_url,
      species: data.species,
      timestamp: new Date(),
      uid: currentUser.uid,
      userRef: db.collection("users").doc(currentUser.uid),
      venue: data.venue,
      // weight: data.weight
      weight: convertedWeight,
    });
  }

  function addCatchVideo(videoUrl, catchId) {
    return db.collection("video_uploads").add({
      catchRef: db.collection("catches").doc(catchId),
      processed: false,
      timestamp: new Date(),
      user: db.collection("users").doc(currentUser.uid),
      userId: currentUser.uid,
      video_url: videoUrl,
    });
  }

  function GetMyCatches() {
    const [fish, setFish] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("catches")
        .where("uid", "==", currentUser.uid)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFish(data);
        });
      return unsubscribe;
    }, [currentUser.uid]);
    return fish;
  }

  function GetAnglerScores() {
    const [entries, setEntries] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("angler_score")
        .orderBy("combinedWeight", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEntries(data);
        });
      return unsubscribe;
    }, []);
    return entries;
  }

  function GetPublicFishAll() {
    const [entries, setEntries] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("public_catches")
        .orderBy("weight", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEntries(data);
        });
      return unsubscribe;
    }, []);
    return entries;
  }

  function GetPublicFishForAngler(anglerId) {
    const [entries, setEntries] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("public_catches")
        .where("uid", "==", anglerId)
        .orderBy("weight", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEntries(data);
        });
      return unsubscribe;
    }, [anglerId]);
    return entries;
  }

  function GetBiggestCommons() {
    const [entries, setEntries] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("public_catches")
        .where("species", "==", "Common")
        .orderBy("weight", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEntries(data);
        });
      return unsubscribe;
    }, []);
    return entries;
  }

  function GetBiggestMirrors() {
    const [entries, setEntries] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("public_catches")
        .where("species", "==", "Mirror")
        .orderBy("weight", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEntries(data);
        });
      return unsubscribe;
    }, []);
    return entries;
  }

  function GetBiggestGrass() {
    const [entries, setEntries] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("public_catches")
        .where("species", "==", "Grass")
        .orderBy("weight", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEntries(data);
        });
      return unsubscribe;
    }, []);
    return entries;
  }

  function GetBiggestThree() {
    const [entries, setEntries] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("public_top_3")
        .where("competition", "==", "arzRhtb2n9Vd9f1Ssr5B") // TODO: Make this dynamic
        .orderBy("combinedWeight", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEntries(data);
        });
      return unsubscribe;
    }, []);
    return entries;
  }

  async function createTeam(data) {
    return new Promise(async (res, rej) => {
      const captain_ref = db.collection("users").doc(data.captain_id);
      const vice_captain_ref = db.collection("users").doc(data.vice_id);
      const third_ref = data.third_id
        ? db.collection("users").doc(data.third_id)
        : null;
      if (third_ref) {
        const teamId = await db
          .collection("teams")
          .add({
            ...data,
            captain_ref,
            vice_captain_ref,
            third_ref,
          })
          .then((doc) => {
            return doc.id;
          });

        await captain_ref.update({ team_id: teamId });
        await vice_captain_ref.update({ team_id: teamId });
        await third_ref.update({ team_id: teamId });
        res();
      } else {
        const teamId = await db
          .collection("teams")
          .add({
            ...data,
            captain_ref,
            vice_captain_ref,
          })
          .then((doc) => {
            return doc.id;
          });
        await captain_ref.update({ team_id: teamId });
        await vice_captain_ref.update({ team_id: teamId });
        res();
      }
    });
  }

  function GetTeams() {
    const [teams, setTeams] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("teams")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: {
              id: doc.id,
              ...doc.data(),
            },
            label: doc.data().name,
          }));
          setTeams(data);
        });
      return unsubscribe;
    }, []);
    return teams;
  }

  function makeMarshall(uid) {
    return db.collection("users").doc(uid).update({
      marshall: true,
    });
  }

  function GetMarshalls() {
    const [users, setUsers] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .where("marshall", "==", true)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUsers(data);
        });
      return unsubscribe;
    }, []);
    return users;
  }

  function marshallCatch(data) {
    return db.collection("marshall_catches").add({
      ...data,
      addedBy: {
        uid: currentUser.uid,
        name: currentUser.display_name || currentUser.email,
      },
      weight: parseFloat(data.weight),
    });
  }

  function updateTeamBoard(teamId, data) {
    return db
      .collection("team_leaderboard")
      .doc(teamId)
      .update({
        ...data,
      });
  }

  function updateTeam(teamId, data) {
    return db
      .collection("teams")
      .doc(teamId)
      .update({
        ...data,
      });
  }

  function GetTeamsLeaderboard() {
    const [teams, setTeams] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("team_leaderboard")
        .orderBy("weight", "descg")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTeams(data);
        });
      return unsubscribe;
    }, []);
    return teams;
  }

  function GetVenueMapData(venueId) {
    const [points, setPoints] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("heatmap_data")
        .where("venue", "==", venueId)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPoints(data);
        });
      return unsubscribe;
    }, [venueId]);
    return points;
  }

  async function getVenueMapDataAsync(venueId, species, weight, days) {
    let query = db.collection("heatmap_data").where("venue", "==", venueId);

    if (species && species.length > 0) {
      query = query.where("species", "in", species);
    }

    // if (weight) {
    //   query = query.where("weight", ">=", parseFloat(weight));
    // }

    if (days) {
      let date = new Date(days);
      query = query.where("timestamp", ">=", date);
    }

    const snap = await query.get();
    if (snap.empty) return [];

    let catchesArray = [];
    snap.docs.map((doc) => {
      if (weight) {
        if (doc.data().weight >= parseFloat(weight)) {
          catchesArray.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      } else {
        catchesArray.push({
          id: doc.id,
          ...doc.data(),
        });
      }
    });

    return catchesArray;
  }

  function GetCollection(collection, whereArray = null, orderByClause = null) {
    const [data, setData] = useState();
    useEffect(() => {
      let query = db.collection(collection);
      if (whereArray !== null) {
        whereArray.map((whereClause) => {
          query = query.where(...whereClause);
        });
      }
      if (orderByClause !== null) {
        query = query.orderBy(...orderByClause);
      }
      const unsubscribe = query.onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          ref: doc.ref,
          value: doc.id,
          label:
            doc.data().name ||
            doc.data().title ||
            `${doc.data().display_name} - ${doc.data().email}` ||
            doc.id,
        }));
        setData(docs);
      });
      return unsubscribe;
    }, [collection]);
    return data;
  }

  async function addStandardDoc(collection, data) {
    const model = await db.collection(collection).add({
      ...data,
      createdAt: new Date(),
      timestamp: data.timestamp || new Date(),
      addedBy: currentUser.uid,
    });
    return model.id;
  }

  function updateStandardDoc(collection, docId, data) {
    return db
      .collection(collection)
      .doc(docId)
      .update({
        ...data,
        updatedAt: new Date(),
        updatedBy: currentUser.uid,
      });
  }

  async function removeFieldFromDoc(collection, docId, fieldName) {
    return db
      .collection(collection)
      .doc(docId)
      .update({
        [fieldName]: firestore.FieldValue.delete(),
      });
  }

  async function getStandardDoc(collection, docId) {
    const docSnap = await db.collection(collection).doc(docId).get();
    return {
      ...docSnap.data(),
      id: docId,
    };
  }

  function getFirestoreGeoPoint(lat, lng) {
    return new firestore.GeoPoint(lat, lng);
  }

  async function addAnglerToComp(compId, anglerId) {
    const anglerRef = db.collection("users").doc(anglerId);

    return await db
      .collection("competitions")
      .doc(compId)
      .update({
        participants: firestore.FieldValue.arrayUnion(anglerId),
        participant_refs: firestore.FieldValue.arrayUnion(anglerRef),
      });
  }

  const generateGeohashes = async () => {
    try {
      // Get all documents from 'venues' collection
      const snapshot = await db.collection("venues").get();

      // Create a batch to batch update the operations (Firestore allows 500 ops per batch)
      const batch = db.batch();

      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.latitude && data.longitude) {
          const lat = parseFloat(data.latitude);
          const lng = parseFloat(data.longitude);

          // Compute geohash
          const geohash = geohashForLocation([lat, lng]);

          // Update document with geohash
          const docRef = db.collection("venues").doc(doc.id);
          batch.update(docRef, { geohash });
        }
      });

      // Commit the batch
      await batch.commit();

      console.log("Geohashes updated successfully!");
    } catch (error) {
      console.error("Error updating geohashes:", error);
    }
  };

  async function addAnglerToFk7(anglerId) {
    const anglerRef = db.collection("users").doc(anglerId);
    return await db
      .collection("competitions")
      .doc("ywtTrly2RISdKSBBdeWh")
      .update({
        participants: firestore.FieldValue.arrayUnion(anglerId),
        participant_refs: firestore.FieldValue.arrayUnion(anglerRef),
      });
  }

  function deleteStandardDoc(collection, docId) {
    return db.collection(collection).doc(docId).delete();
  }

  function GetTeamsEntries(compId) {
    const [teams, setTeams] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("team_entries")
        .where("competition", "==", compId)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTeams(data);
        });
      return unsubscribe;
    }, []);
    return teams;
  }

  const value = {
    GetIncomingCatches,
    getCatchVids,
    GetApprovedCatches,
    GetSiteEmails,
    GetAppUsers,
    approveCatch,
    declineCatch,
    togglePro,
    submitCatch,
    addCatchVideo,
    GetMyCatches,
    GetAnglerScores,
    GetBiggestCommons,
    GetBiggestMirrors,
    GetBiggestGrass,
    GetPublicFishAll,
    GetBiggestThree,
    GetPublicFishForAngler,
    GetAllCatches,
    createTeam,
    GetTeams,
    makeMarshall,
    GetMarshalls,
    marshallCatch,
    updateTeamBoard,
    GetTeamsLeaderboard,
    updateTeam,
    GetVenueMapData,
    GetCollection,
    addStandardDoc,
    getFirestoreGeoPoint,
    updateStandardDoc,
    getVenueMapDataAsync,
    getStandardDoc,
    removeFieldFromDoc,
    addAnglerToComp,
    generateGeohashes,
    addAnglerToFk7,
    GetTeamsEntries,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
